<template>
  <div class="register-box">
    <login-head title="找回密码" />
    <div class="register-content">
      <div class="register-from">
        <div class="register-title">
          <span>找回密码</span>
        </div>
        <div class="register-from-box">
          <div>
            <p class="new-password-title">设置新密码</p>
            <span class="new-password-desc"
              >如果您有其他设备使用此帐号，设置新密码后需重新登录，以确保正常使用鸿联云服务。</span
            >
            <div class="forget-from-info">
              <div>
                <a-input-password
                  size="large"
                  v-model="newPassword"
                  placeholder="请输入6~20位密码，至少含字母和数字，不能包含空格"
                />
              </div>
              <div>
                <a-input-password
                  size="large"
                  v-model="confirmNewPassword"
                  placeholder="请确认密码"
                />
              </div>
              <a-button
                size="large"
                class="btn-red register-btn"
                @click="handleOk"
                >确 定
              </a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="register-footer-protocol">
<!--        <div>
          <span @click="$router.push('/protocol/serve')">鸿联云帐号用户协议</span>
          <span @click="$router.push('/protocol')">关于鸿联云帐号与隐私的声明</span>
&lt;!&ndash;          <span>常见问题</span>&ndash;&gt;
        </div>-->
        <p>Copyright © 鸿联云, All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import loginHead from "@/page/components/loginHead.vue";
import { resetPasswordByMobile } from "@/api/common";

export default {
  components: {
    loginHead,
  },
  computed: {
    resetPasswordToken() {
      return this.$route.query.token;
    },
  },
  data() {
    return {
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  methods: {
    handleOk() {
      const { resetPasswordToken, newPassword, confirmNewPassword } = this;
      if (!newPassword) {
        this.$message.error("请输入新密码");
        return;
      }
      if (!confirmNewPassword) {
        this.$message.error("请确认密码");
        return;
      }
      resetPasswordByMobile({
        token: resetPasswordToken,
        password: newPassword,
      }).then(() => {
        this.$message.success("操作成功！");
        this.$router.push({
          name: "login",
          params: {
            name: "reset",
          },
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.obtain-code {
  color: #EA0B06;
  cursor: pointer;
}

.register-box {
  height: 100%;
  background: #f7f8fa;

  .register-content {
    height: calc(100% - 64px);
    padding: 24px 0;

    .register-from {
      background: #fff;
      width: 960px;
      margin: 0 auto;
      border-radius: 4px;

      .register-title {
        padding: 0 24px;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > span {
          font-size: 18px;
        }
      }

      .register-from-box {
        min-height: 584px;
        padding: 56px 0;

        > div {
          width: 480px;
          margin: 0 auto;

          .new-password-title {
            line-height: 15px;
            font-size: 16px;
            margin-bottom: 14px;
          }

          .new-password-desc {
            display: block;
            font-size: 14px;
            line-height: 22px;
            color: #8c8c8c;
            margin-bottom: 14px;
          }

          .forget-from-info {
            > div {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .register-btn {
            width: 100%;
            font-size: 14px;
            margin-top: 40px;
          }
        }
      }
    }

    .register-footer-protocol {
      margin-top: 40px;

      > div {
        display: flex;
        justify-content: center;

        > span {
          line-height: 13px;
          font-size: 12px;
          color: #bfbfbf;
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid #bfbfbf;
          cursor: pointer;

          &:last-child {
            margin: 0;
            border: 0;
          }
        }
      }

      > p {
        line-height: 13px;
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}
</style>
